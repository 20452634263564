import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Title from '../components/title'
import Card from "../components/card"
// import Paper from '@material-ui/core/Paper';
import Paper from '@mui/material/Paper'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  // paper: {
  //   padding: '6px 16px',
  //   margin: '10px',
  //   background: '#eceff1',
  // },
  secondaryTail: {
    backgroundColor: 'theme.palette.secondary.main',
  },
}));

export default function MyTimeline() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Title>History</Title>
        <Timeline align="alternate">

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                4/2021 - Present
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <DotIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {/* <Paper elevation={1} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  Solutions Engineer | <strong>Anomali</strong>
                </Typography><Typography>
                </Typography>
              </Paper> */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Paper elevation={3} style={{
                    padding: '6px 16px',
                    margin: '10px',
                    background: '#eceff1'
                  }}>
                    <Typography variant="h6" component="h6">Solutions Engineer | <strong>Anomali</strong></Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Scope, develop, test and maintain custom integrations and feeds for our clients.
                    Help augment/support client-facing Anomali Research Team tools, such as such as Peripheral and MHN.
                    Help internal Anomali teams in creating/managing/updating custom tools, such as Broker and MHN.
                    Supporting/Training Sales in scoping custom feeds and integrations.
                    Provide ongoing support for our SDK's as a paid for ARR-based service offering.
                    Helping CSO in troubleshooting custom/feeds integrations or Anomali products that requires reviewing the code and any issues associated with it.
                    Assist the Dev Integration or Feeds team with any overflow development work.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                7/2019 - 4/2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <DotIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {/* <Paper elevation={1} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  Solutions Developer | <strong>Deloitte</strong>
                </Typography><Typography>
                </Typography>
              </Paper> */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Paper elevation={3} style={{
                    padding: '6px 16px',
                    margin: '10px',
                    background: '#eceff1'
                  }}>
                    <Typography variant="h6" component="h6">
                      Solutions Developer | <strong>Deloitte</strong>
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    * Working for a client under the Government and Public Services (GPS) sector, with a concentration in insurance data analytics, data pipelines and full stack web development utilizing a Public Trust Clearance.
                    * Built ETL pipelines and web applications with python, Javascript and a SQL based backend that facilitates the mass extraction, transformation and loading of various insurance documents using OCR to extract and organize data.
                    * Built front end views for data entry analysis and verification with a focus on data density and efficiency with React, Javascript and Material-UI and a ASP.NET, C# for the business logic, routing and authentication. Implemented various REST APIs for ETL pipelines. Designed and implemented changes in UI/UX experiences to drive efficiencies in data entry and data reconciliation workflows.
                    * Built and deployed a log analysis pipeline in Python that analyzed user activity in the web application to derive meaningful business insights then exported to PowerBI for visualizations on user metrics and further analysis.
                    * Lead Architect of a pilot data extraction solution, that is able to extract structured and unstructured data from pension plan documentation into a data processing pipeline, saving thousands of hours of manual extraction work and hundreds of thousands of dollars in client expense.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                2019
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <DotIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} style={{
                padding: '6px 16px',
                margin: '10px',
                background: '#eceff1'
              }}>
                <Typography variant="h6" component="h6">
                  Graduation | <strong>UCF</strong>
                </Typography>B.S. Computer Science<Typography>
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                2018
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <DotIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} style={{
                padding: '6px 16px',
                margin: '10px',
                background: '#eceff1'
              }}>
                <Typography variant="h6" component="h1">
                  Intern | <strong>ServiceNow</strong>
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                2017
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <DotIcon />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} style={{
                padding: '6px 16px',
                margin: '10px',
                background: '#eceff1'
              }}>
                <Typography variant="h6" component="h1">
                  Research Assistant | <strong>UCF</strong>
                </Typography>
                <Typography></Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

        </Timeline>
      </Card>
    </ React.Fragment >
  );
}
