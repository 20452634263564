import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Title from '../components/title'
import SEO from "../components/seo"
import Card from"../components/card"
import TimeLine from "../components/timeline"
import { Typography } from "@material-ui/core"
import resumePDF from "../images/resumelong.pdf"

const AboutMe = () => (
  <React.Fragment>
    <SEO title="About" />
    <Card>
      <Title>About Me</Title>
      <Typography>
        A Full Stack Engineer currently working with Python, JS, React, C#, and .NET to solve client problems in a scalable and efficient way. I'm intrested in tech(obviously), philosophy, macroeconomics and Bitcoin, I am constantly on the lookout for new products and technologies that I can use to upgrade my own skillset and tackle problems that I may see in the world and face day to day. 
        <br /><br />
        When i'm not on the computer i'm probably playing with my <a href="/cat/">cat</a>. I love to go on walks, listen to podcasts, read, make beats, and cook.
        
        {/* Indian born American made. I've been passionate about anything technology from a young age. Computer Science is one of the few fields where new ideas and information is generated daily through code, unhindered by bureaucracy. I enjoy learning about new technologies and am constantly on the lookout for things that I can use to leverage my own skillset and the problems that I face on day to day. I learn quickly but I acknowledge that failure is a frequent friend in programming. */}
      </Typography>
    </Card>
    <TimeLine />
  </React.Fragment>  
)

export default AboutMe
